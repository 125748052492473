import { Button, Image, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { Loader } from "../core";
import Header from "../Components/Header";
import Close from "../../svg/close";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import { ActiveCard } from "../Components/BenefitsCards";
import benefitSvg from "../../../assets/images/benefit.svg";
import { useSelector } from "react-redux";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { useNavigate } from "react-router-dom";
import Paths from "../../../routes/Paths";
import logger from "../../../utils/logger";
import { handleApiError, isApiSuccessful } from "../../../utils/functions";
import { BenefitTypes } from "../../../utils/enums";

const EarnedVouchers = () => {
  const dimensions = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedVouchers, setSelectedVouchers] = useState("AIRPORT_LOUNGE");
  const [earnedBenefits, setEarnedBenefits] = useState([]);
  const [earnedVouchersByType, setEarnedVouchersByType] = useState([]);

  const navigateToPath = useNavigateToPath();
  const navigate = useNavigate();

  const getSubscribedBenefits = async () => {
    setLoading(true);
    let accountId = localStorage.getItem("cardId");
    try {
      const res = await ManageBenefitService.getEarnedBenefits(accountId);
      if (isApiSuccessful(res)) {
        setEarnedBenefits(res.data);
      } else {
        logger.error({
          message: `An exception occurred while fetching earned vouchers for accountId ${accountId}`,
          responseData: res.data,
          accountId: accountId,
          correlationId: res.headers.x_correlation_id,
        });
        handleApiError(res, navigate);
      }
    } catch (error) {
      logger.error({
        message: `An exception occurred while fetching earned vouchers for accountId ${accountId}`,
        error: error?.message,
        accountId: accountId,
        stackTrace: error?.stack,
      });
      navigate(Paths.GENERIC_ERROR);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscribedBenefits();
  }, []);

  useEffect(() => {
    if (earnedBenefits.length > 0) {
      setEarnedVouchersByType(
        earnedBenefits.filter((item) => item.benefit.type === selectedVouchers)
      );
    }
  }, [earnedBenefits, selectedVouchers]);

  const handleGoBack = () => {
    navigateToPath("/Features/ManageFeatures");
  };

  return loading ? (
    <View alignItems="center" justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} bgColor="#FFFFFF" width={"100%"}>
        <View
          w="100%"
          _web={{ maxW: "412px" }}
          alignItems="center"
          justifyContent="center"
          bgColor={"#FFFFFF"}
          height={dimensions.height}
        >
          <Loader color={"#F37435"} width={120} height={120} />
        </View>
      </View>
    </View>
  ) : (
    <View alignItems="center" justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} bgColor="#FFFFFF" width={"100%"}>
        {
          <>
            <Header />
          </>
        }
        {
          <View
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            px="16px"
            py="20px"
            shadow={"1"}
          >
            <View flexDirection="row" alignItems="center">
              <View
                mr="8px"
                alignItems="center"
                cursor="pointer"
                onClick={handleGoBack}
              >
                <Image
                  source={{
                    uri:
                      process.env.REACT_APP_AWS_S3_BUCKET_URL + "backArrow.svg",
                  }}
                  height="24px"
                  width="24px"
                  alt=""
                />
              </View>
              <Text
                //   color="#6D3078"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="20px"
                lineHeight="24px"
              >
                Earned Vouchers
              </Text>
            </View>
          </View>
        }
        <View
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={"8px"}
          p={"16px"}
          borderBottomWidth="1px"
          borderBottomColor="#f2f2f2"
        >
          <View
            bg={selectedVouchers === BenefitTypes.AIRPORT_LOUNGE ? "#FEFAFF" : "#FFF"}
            borderRadius="4px"
            mr="8px"
            px="12px"
            py="6px"
            borderWidth="2px"
            borderColor={
              selectedVouchers === BenefitTypes.AIRPORT_LOUNGE ? "#6D3078" : "#EBEDF0"
            }
            onClick={() => setSelectedVouchers(BenefitTypes.AIRPORT_LOUNGE)}
            cursor="pointer"
          >
            <Text
              fontFamily={"Quicksand"}
              fontSize={"12px"}
              fontWeight={"600"}
              lineHeight={"20px"}
              color={
                selectedVouchers === BenefitTypes.AIRPORT_LOUNGE ? "#6D3078" : "#303030"
              }
            >
              Lounge Vouchers
            </Text>
          </View>
          <View
            bg={selectedVouchers === BenefitTypes.POSHVINE_VOUCHER ? "#FEFAFF" : "#FFF"}
            borderRadius="4px"
            mr="8px"
            px="12px"
            py="6px"
            borderWidth="2px"
            borderColor={
              selectedVouchers === BenefitTypes.POSHVINE_VOUCHER ? "#6D3078" : "#EBEDF0"
            }
            cursor="pointer"
            onClick={() => setSelectedVouchers(BenefitTypes.POSHVINE_VOUCHER)}
          >
            {" "}
            <Text
              fontFamily={"Quicksand"}
              fontSize={"12px"}
              fontWeight={"600"}
              lineHeight={"20px"}
              color={
                selectedVouchers === BenefitTypes.POSHVINE_VOUCHER ? "#6D3078" : "#303030"
              }
            >
              Membership Vouchers
            </Text>
          </View>
        </View>

        {earnedVouchersByType.length > 0 ? (
          <View p="16px">
            {earnedVouchersByType.map((item, index) => (
              <View mb="12px" key={index}>
                <ActiveCard data={{...item, isEarnedVoucher: true}} />
              </View>
            ))}
          </View>
        ) : (
          <View p="16px">
            <View
              marginTop={"20%"}
              margin={"auto"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Image
                source={{
                  uri: benefitSvg,
                }}
                alt=""
                height="115px"
                width="115px"
              />
              <Text
                textAlign={"center"}
                fontFamily={"Quicksand"}
                fontSize={"20px"}
                fontWeight={"700"}
                lineHeight={"28px"}
                color={"#303030"}
              >
                You don’t have any earned
              </Text>
              <Text
                textAlign={"center"}
                fontFamily={"Quicksand"}
                fontSize={"20px"}
                fontWeight={"700"}
                lineHeight={"28px"}
                color={"#303030"}
              >
                benefits!
              </Text>
              <Text
                px={"24px"}
                py={"10px"}
                borderRadius={"4px"}
                borderWidth={"1px"}
                borderColor={"#F37435"}
                color={"#F37435"}
                background={"#fff"}
                fontSize={"14px"}
                fontWeight={"700"}
                lineHeight={"22px"}
                fontFamily={"Quicksand"}
                cursor={"pointer"}
                marginTop={"20px"}
                onClick={() => handleGoBack()}
              >
                Add Benefits
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default EarnedVouchers;
