import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowDimensions } from "react-native";
import {
  View,
  Text,
  Input,
  Link,
  useToast,
  ScrollView,
  Modal,
  Image,
  Button,
} from "native-base";
import { WhiteButton } from "../../core/buttons";
import styles from "./styles";
import { consoleError, getPageNameByRoute } from "../../../utils/functions";
import { showToast1 } from "../../core/toast";
import Loader from "../../core/loader";
import ManageBenefitService from "../../../services/ManageBenefitService";
import hide from "../../../assets/images/HideBlack.svg";
import show from "../../../assets/images/show.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsTermsAccepted } from "../../../store/actions/ManageBenefits";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Platform } from "react-native";
import logger from "../../../utils/logger";
import queryString from "query-string";
import {
  AU0101_DEEP_LINK,
  ProgramConfigs,
  RequestingPage,
} from "../../../utils/constants";
import { Channels, ErrorReasons, TrackEventTypes } from "../../../utils/enums";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import CustomModal from "../Components/customModal/CustomModal";

const intervalTime = 60;

const Otp = () => {
  const windowDimensions = useWindowDimensions();
  const nativeToast = useToast();
  const dispatch = useDispatch();
  const location = useLocation();

  const theme = useSelector((state) => state.theme);
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const otpStore = useSelector((state) => state.otp);

  const [loading, setLoading] = useState(true);
  const [showOtp, setShowOtp] = useState(
    window.navigator.platform === "iPhone" ? true : false
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [mobileVerificationRefId, setMobileVerificationRefId] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [errorMsgOpen, setErrorMsgOpen] = useState(false);
  const [error, setError] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [seconds, setSeconds] = useState(intervalTime);
  const [pin, setPin] = useState(["", "", "", "", "", ""]);
  const [showActivateCardModal, setShowActivateCardModal] = useState(false);
  const navigate = useNavigate();
  const ac = new AbortController();

  const trackEventsAdobeAnalytics = useTrackEvents();

  const interval = useRef(null);
  const otpId = useRef("");
  const otp0 = useRef(null);
  const otp1 = useRef(null);
  const otp2 = useRef(null);
  const otp3 = useRef(null);
  const otp4 = useRef(null);
  const otp5 = useRef(null);

  useEffect(() => {
    if (seconds < 1) {
      clearInterval(interval.current);
    }
  }, [seconds]);

  // useEffect(() => {
  //   if(pin[0]){
  //     setShowOtp(false)
  //   }
  // }, [pin]);

  const queryParams = queryString.parse(location.search);
  let accountId = queryParams.accountId || localStorage.getItem("cardId");

  useEffect(() => {
    (async () => {
      try {
        if (!otpStore.mobileNumber && !otpStore.mobileVerificationRefId) {
          logger.error({
            message: `An error occurred in otpStore for accountId ${accountId}`,
          });
          navigate("/Features/GenericError");
        } else {
          setMobileVerificationRefId(otpStore.mobileVerificationRefId);
        }
        setSeconds(intervalTime);
        interval.current = setInterval(() => {
          setSeconds((seconds) => seconds - 1);
        }, 1000);
      } catch (error) {
        logger.error({
          message: `An exception occurred in otpstore for accountId ${accountId}`,
          error: error?.message,
          accountId: accountId,
          stackTrace: error?.stack,
        });
        otpStore.onError(error);
        return;
      }
    })();

    if (resendCount < 1) setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pin.join("").length === 6) {
      setSubmitLoading(true);
      verifyOtp();
      setSubmitLoading(false);
    }
  }, [pin.join("").length]);

  const toast = (message) => {
    showToast1({ nativeToast, message });
  };

  useEffect(() => {
    if (window.OTPCredential && ac) {
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          setPin(otp.code.split(""));
        })
        .catch((err) => {
          logger.error({
            message: `An exception occurred in otp store for accountId ${accountId}`,
            error: err?.message,
            accountId: accountId,
            stackTrace: err?.stack,
          });
          console.log(err);
        });
    }
  }, []);
  const verifyOtp = async () => {
    setSubmitLoading(true);

    if (errorMsgOpen || resendCount >= 3) {
    } else {
      try {
        const response = await ManageBenefitService.verifyLoginOtp({
          mobileVerificationRefId: mobileVerificationRefId,
          otp: pin.join(""),
        });
        const result = response.data;

        if (
          response.data.verificationStatus === "VERIFIED" ||
          response.data.status === "VERIFIED"
        ) {
          setError("");
          sessionStorage.setItem("triggerAdobeEvent", true);
          if (manageBenefits.channel === "CCA") {
            const response =
              await ManageBenefitService.activateOnboardingBenefits(
                {
                  mobileVerificationRefId: mobileVerificationRefId,
                  channel: manageBenefits.channel,
                },
                localStorage.getItem("applicantId")
              );

            if (response.data.status === "SUCCESS") {
              setModalOpen(true);
              otpStore.onSuccess();
            } else {
              logger.error({
                message: `An error occurred while activateOnboardingBenefits for accountId ${accountId}`,
                responseData: response.data,
                accountId: accountId,
                correlationId: response.headers.x_correlation_id,
              });

              navigate("/Features/GenericError", {
                state: {
                  x_correlation_id: response.headers.x_correlation_id,
                },
              });
            }
          } else if (manageBenefits.channel === "CCO") {
            otpStore.onSuccess(result?.mobileVerificationRefId);
          } else if (location?.state?.requestingPage === "checkout") {
            sessionStorage.setItem("triggerAdobeEvent", false);
            otpStore.onSuccess(result?.mobileVerificationRefId);
          } else {
            try {
              const response = await ManageBenefitService.manageCard({
                mobileVerificationRefId: mobileVerificationRefId,
                mobileNumber: otpStore.mobileNumber,
                cobrandId:
                  ProgramConfigs[localStorage.getItem("program")].cobrandId[
                    process.env.REACT_APP_ENV
                  ],
              });

              if (response.data.success) {
                window.location.href = response.data.redirectLink;
              } else if (
                response.status === 403 &&
                response.data.reason === ErrorReasons.CARD_NOT_ACTIVE
              ) {
                setShowActivateCardModal(true);
              } else {
                logger.error({
                  message: `An error occurred while manageCard for accountId ${accountId}`,
                  responseData: response.data,
                  accountId: accountId,
                  correlationId: response.headers.x_correlation_id,
                });
                navigate("/Features/GenericError", {
                  state: {
                    x_correlation_id: response.headers.x_correlation_id,
                  },
                });
              }
              setSubmitLoading(false);
            } catch (err) {
              logger.error({
                message: `An exception occurred while activateOnboardingBenefits or manageCard for accountId ${accountId}`,
                error: err?.message,
                accountId: accountId,
                stackTrace: err?.stack,
              });
              console.log(err);
              navigate("/Features/GenericError");
            }
          }
          return;
        } else {
          if (response.data.failureReason.startsWith("Invalid OTP")) {
            setPin(["", "", "", "", "", ""]);
            otp0.current.focus();
            setError(result?.failureReason);
            trackEventsAdobeAnalytics({
              linkName:
                location?.state?.requestingPage !== "checkout"
                  ? TrackEventTypes.LOGIN_OTP_FAILURE
                  : TrackEventTypes.BENEFIT_ACTIVATION_OTP_FAILURE,
              error: response?.data?.failureReason,
            });
          } else {
            logger.error({
              message: `An error occurred while activateOnboardingBenefits or manageCard for accountId ${accountId}`,
              responseData: response.data,
              accountId: accountId,
              correlationId: response.headers.x_correlation_id,
            });
            consoleError(result?.failureReason);
            setSubmitLoading(false);
            setErrorMsgOpen(true);
            setError("");
          }
        }
      } catch (error) {
        logger.error({
          message: ` An exception occurred while activateOnboardingBenefits or manageCard for accountId ${accountId}`,
          error: error?.message,
          accountId: accountId,
          stackTrace: error?.stack,
        });
        trackEventsAdobeAnalytics({
          linkName:
            location?.state?.requestingPage !== "checkout"
              ? TrackEventTypes.LOGIN_OTP_FAILURE
              : TrackEventTypes.BENEFIT_ACTIVATION_OTP_FAILURE,
          error: error?.reason,
        });
        consoleError(error);
        otpStore.onError(error);
        return;
      }
    }

    setSubmitLoading(false);
  };

  const resendLoginOtp = async () => {
    setResendLoading(true);
    if (resendCount >= 3) {
      setError(
        "You have reached maximum resend Attempts. Please generate new OTP"
      );
    } else {
      try {
        let requestData = null;
        if (location?.state?.requestingPage === "checkout") {
          requestData = {
            accountId: accountId,
            scope: "BENEFIT_ACTIVATION_AUTH",
            benefitIds: manageBenefits.benefitsListId,
          };
        } else if (manageBenefits.channel === "CCO") {
          requestData = {
            accountId: accountId,
            scope: "CCO_BENEFIT_ACTIVATION",
            benefitIds: manageBenefits.benefitsListId,
          };
        } else {
          requestData = {
            mobileNumber: otpStore.mobileNumber,
            scope: "LOGIN",
            cobrandId:
              ProgramConfigs[localStorage.getItem("program")].cobrandId[
                process.env.REACT_APP_ENV
              ],
          };
        }
        const response = await ManageBenefitService.generateOtp(requestData);
        // const response = await ManageBenefitService.resendLoginOtp({
        //   mobileVerificationRefId: otpStore.mobileVerificationRefId,
        //   mobileNumber: otpStore.mobileNumber,
        // });
        if (response.data.status !== "SUCCESS") {
          setError(response.data.failureReason);
        } else {
          setResendCount(resendCount + 1);
          setError("");
          setMobileVerificationRefId(response.data.mobileVerificationRefId);
          setSeconds(intervalTime);
          interval.current = setInterval(() => {
            setSeconds((seconds) => seconds - 1);
          }, 1000);

          trackEventsAdobeAnalytics({
            linkName: TrackEventTypes.RESEND_OTP,
            linkPageName: getPageNameByRoute(location.pathname),
            otpRequested: "y",
          });
        }
      } catch (err) {
        logger.error({
          message: ` An exception occurred while generateLoginOtp for accountId ${accountId}`,
          error: err?.message,
          accountId: accountId,
          stackTrace: err?.stack,
        });

        console.log(err);
      }
    }
    setResendLoading(false);
  };

  const isAU0101 =
    location?.state?.requestingPage !== RequestingPage.LOGIN &&
    manageBenefits.channel === Channels.MB;

  const handleActivateClick = () => {
    window.location.href = AU0101_DEEP_LINK;
    setTimeout(() => {
      setShowActivateCardModal(false);
      navigate(ProgramConfigs[localStorage.getItem("program")].loginUrl);
    }, 500);
  };

  const handleCloseClick = () => {
    navigate(ProgramConfigs[localStorage.getItem("program")].loginUrl);
    setShowActivateCardModal(false);
  };

  return loading ? (
    <View
      height={windowDimensions.height}
      alignItems="center"
      justifyContent="center"
    >
      <Loader color={theme.color1} width={120} height={120} />
    </View>
  ) : (
    <View alignItems="center" justifyContent="center">
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        mt={12}
        closeOnOverlayClick={false}
        maxHeight={"90vh"}
      >
        <Modal.Content maxWidth="600px" w="90%">
          {/* <Modal.CloseButton /> */}

          <Modal.Body bgColor="#ffffff">
            <View bgColor="#ffffff" px="24px" pb="40px" pt={"5px"}>
              <View>
                <Image
                  source={{
                    uri:
                      process.env.REACT_APP_AWS_S3_BUCKET_URL +
                      "benefitActivateSuccessfull.png",
                  }}
                  height="100px"
                  width="195px"
                  alt=""
                />
              </View>
              <View alignItems="center" mt="24px">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="24px"
                  lineHeight="32px"
                >
                  Your Feature(s) selection has been captured
                </Text>
                <Text
                  color="#303030"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  Features will be activated automatically once card is issued.
                  You can always modify, add, track and manage features on your
                  Card through AU 0101 NetBanking/Mobile Banking. You will now
                  be re-directed to complete the LIT Credit Card application
                  process.
                </Text>
              </View>
            </View>
            <View
              cursor="pointer"
              onClick={() => {
                setModalOpen(false);
                dispatch(setIsTermsAccepted({ termsAccepted: false }));
                window.location.href = manageBenefits.callBackUrl;
              }}
              w="90%"
              alignItems="center"
              justifyContent="center"
              bgColor="#F37435"
              p="10px 140px"
              borderRadius="4px"
              margin="auto"
            >
              <Text
                color="#FFFFFF"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
              >
                OK
              </Text>
            </View>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <ScrollView>
        <View
          _web={{ maxW: "412px" }}
          width="100%"
          bgColor={"#FFFFFF"}
          height={windowDimensions.height}
          minHeight={"750px"}
        >
          <View flex={1}>
            {!isAU0101 && (
              <View
                p="8px 12px"
                backgroundColor={"#fff2f0"}
                flexDirection="row"
              >
                <View>
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "AU0101Logo.png",
                    }}
                    height="63px"
                    width="60px"
                    alt=""
                  />
                </View>
                {/* <View ml="12px" alignItems={"center"}>
                <Text
                  color="#6d3078"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="24px"
                  lineHeight="26px"
                >
                  Build Your LIT Credit Card
                </Text>
              </View> */}
              </View>
            )}
            <View mt="16px" px="5" alignItems={"center"}>
              <Text
                {...styles.otpVerificationText}
                fontFamily={theme.fontFamily}
                color="#6D3078"
              >
                Mobile Number Verification
              </Text>
              <Text {...styles.saveSettingText} textAlign="center" mt="20px">
                One time password (OTP) has been sent to your registered mobile
                number{" "}
                <Text color="#000" bold>
                  {`XXXXXX${otpStore.mobileNumber[6]}${otpStore.mobileNumber[7]}${otpStore.mobileNumber[8]}${otpStore.mobileNumber[9]}`}
                </Text>
              </Text>
            </View>
            <View mt="16px" px="5" alignItems={"center"}>
              <Text
                {...styles.otpVerificationText}
                fontFamily={theme.fontFamily}
                color="#6D3078"
              >
                Enter OTP
              </Text>
            </View>
            <View
              {...styles.pinMainView}
              alignItems="center"
              w="100%"
              px="5"
              backgroundColor={"#fff2f0"}
            >
              <View {...styles.pinMainView1} w="75%">
                {/* <input
                  type="text"
                  id="fname"
                  name="fname"
                  ref={otp0}
                  value={pin[0]}
                ></input> */}
                <Input
                  {...styles.textInput}
                  id="single-factor-code-text-field"
                  name="password"
                  autoComplete="one-time-code"
                  secureTextEntry={!showOtp}
                  bgColor={Boolean(error) ? "#FDF6F6" : "fff"}
                  ref={otp0}
                  value={pin[0]}
                  autocomplete="one-time-code"
                  maxLength="6"
                  onChangeText={(text) => {
                    if (text.length === 1) {
                      setPin([text, pin[1], pin[2], pin[3], pin[4], pin[5]]);
                      if (text !== "") {
                        otp1.current.focus();
                      }
                    } else if (text.length === 6) {
                      setPin(text.split(""));
                      otp5.current.focus();
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  secureTextEntry={!showOtp}
                  bgColor={Boolean(error) ? "#FDF6F6" : "fff"}
                  ref={otp1}
                  value={pin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[1] === "") {
                      otp0.current.focus();
                      setPin(["", "", pin[2], pin[3], pin[4], pin[5]]);
                    }
                  }}
                  onChangeText={(text) => {
                    setPin([pin[0], text, pin[2], pin[3], pin[4], pin[5]]);
                    if (text !== "") {
                      otp2.current.focus();
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  secureTextEntry={!showOtp}
                  bgColor={Boolean(error) ? "#FDF6F6" : "fff"}
                  ref={otp2}
                  value={pin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[2] === "") {
                      otp1.current.focus();
                      setPin([pin[0], "", "", pin[3], pin[4], pin[5]]);
                    }
                  }}
                  onChangeText={(text) => {
                    setPin([pin[0], pin[1], text, pin[3], pin[4], pin[5]]);
                    if (text !== "") {
                      otp3.current.focus();
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  secureTextEntry={!showOtp}
                  bgColor={Boolean(error) ? "#FDF6F6" : "fff"}
                  ref={otp3}
                  value={pin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[3] === "") {
                      otp2.current.focus();
                      setPin([pin[0], pin[1], "", "", pin[4], pin[5]]);
                    }
                  }}
                  onChangeText={(text) => {
                    setPin([pin[0], pin[1], pin[2], text, pin[4], pin[5]]);
                    if (text !== "") {
                      otp4.current.focus();
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  secureTextEntry={!showOtp}
                  bgColor={Boolean(error) ? "#FDF6F6" : "fff"}
                  ref={otp4}
                  value={pin[4]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[4] === "") {
                      otp3.current.focus();
                      setPin([pin[0], pin[1], pin[2], "", "", pin[5]]);
                    }
                  }}
                  onChangeText={(text) => {
                    setPin([pin[0], pin[1], pin[2], pin[3], text, pin[5]]);
                    if (text !== "") {
                      otp5.current.focus();
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  secureTextEntry={!showOtp}
                  bgColor={Boolean(error) ? "#FDF6F6" : "fff"}
                  ref={otp5}
                  value={pin[5]}
                  onKeyPress={({ nativeEvent }) => {
                    if (nativeEvent.key === "Backspace" && pin[5] === "") {
                      otp3.current.focus();
                      setPin([pin[0], pin[1], pin[2], pin[3], "", ""]);
                    }
                  }}
                  onChangeText={(text) => {
                    setPin([pin[0], pin[1], pin[2], pin[3], pin[4], text]);
                    if (text !== "") {
                      otp5.current.blur();
                    }
                  }}
                />
                <View ml="4px" alignItems={"center"} justifyContent="center">
                  {showOtp ? (
                    <View onClick={() => setShowOtp(false)}>
                      {" "}
                      <Image
                        source={{ uri: show }}
                        height="16px"
                        width="16px"
                        alt=""
                      />
                    </View>
                  ) : (
                    <View onClick={() => setShowOtp(true)}>
                      <Image
                        source={{ uri: hide }}
                        height="16px"
                        width="16px"
                        alt=""
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>
            {pin.join("") === "" && (
              <View px="5" mt="12px" display={Boolean(error) ? "flex" : "None"}>
                <Text
                  fontFamily={theme.fontFamily}
                  font-weight="700"
                  font-size="12px"
                  line-height="18px"
                  letter-spacing="0.02em"
                  color="#FF3737"
                >
                  {error}
                </Text>
              </View>
            )}
            {submitLoading && (
              <View px="5" mt="12px" display={Boolean(error) ? "flex" : "None"}>
                <Text
                  font-weight="700"
                  font-size="12px"
                  line-height="18px"
                  letter-spacing="0.02em"
                  color="#808080"
                >
                  Verifying OTP please wait...
                </Text>
              </View>
            )}
            {errorMsgOpen && (
              <View px="5">
                <Text
                  color="#FF3737"
                  fontWeight="600"
                  fontFamily="Quicksand"
                  fontSize="12px"
                  lineHeight="26px"
                >
                  The OTP has expired. Please generate new OTP
                </Text>
              </View>
            )}
            <View mt="32px" px="5" alignItems={"center"}>
              {resendLoading ? (
                <Text {...styles.resendOtp}>
                  Give us a moment to resend the OTP…
                </Text>
              ) : (
                <Text {...styles.resendOtp}>
                  Did not receive OTP?{" "}
                  <Link
                    _text={{
                      color: seconds < 1 ? "blue.400" : "#808080",
                    }}
                    _web={{
                      mb: 1,
                    }}
                    isUnderlined={seconds < 1}
                    onPress={() => {
                      if (seconds < 1) {
                        resendLoginOtp();
                      }
                    }}
                  >
                    Resend
                  </Link>
                  <Text display={seconds < 1 ? "none" : "inline"}> in </Text>
                </Text>
              )}
              {seconds >= 1 && (
                <View
                  mt="16px"
                  alignItems={"center"}
                  width="160px"
                  height={"160px"}
                >
                  <CircularProgressbar
                    value={seconds}
                    maxValue={60}
                    text={`00:${seconds >= 10 ? seconds : `0${seconds}`}`}
                    strokeWidth="2"
                    styles={buildStyles({
                      // Colors
                      pathColor: `#F37435`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />

                  {/* <View
                  borderColor={"#F37435"}
                  borderWidth="1px"
                  padding={"54px 12px"}
                  borderRadius="100%"
                >
                  <Text
                    color="#000"
                    bold
                    fontSize={"36px"}
                    display={seconds === 0 ? "none" : "inline"}
                  >
                    00:{seconds >= 10 ? seconds : `0${seconds}`}
                  </Text>
                </View> */}
                </View>
              )}
            </View>
          </View>
        </View>
        <View
          {...styles.button}
          alignItems="center"
          justifyContent="center"
          position="fixed"
          width="100%"
          _web={{ maxW: "412px" }}
        >
          {/* <ColorButton
            color="#F37435"
            text={
              errorMsgOpen || resendCount >= 3 ? "Generate OTP" : "Verify OTP"
            }
            isDisabled={pin.join("").length !== 6 && resendCount > 3}
            onPress={verifyOtp}
            isLoading={submitLoading}
          /> */}
          <WhiteButton
            color="#F37435"
            text="Cancel"
            onPress={() => otpStore.onCancel()}
          />
        </View>
      </ScrollView>

      <CustomModal
        isOpen={showActivateCardModal}
        overlayColor={"rgba(48, 48, 48, 0.8)"}
      >
        <View
          width={"90%"}
          maxWidth={"550px"}
          backgroundColor={"white"}
          borderRadius={"12px"}
          overflow={"hidden"}
        >
          <View backgroundColor={"#FEF2F0"} py={"20px"} px={"16px"}>
            <Text
              color={"#6D3078"}
              fontFamily={"Quicksand"}
              fontSize={"16px"}
              fontWeight={"600"}
              lineHeight={"24px"}
            >
              Activate Your Credit Card
            </Text>
          </View>
          <View p={"16px"} alignItems={"center"}>
            <Text
              color={"#303030"}
              fontFamily={"Quicksand"}
              fontSize={"14px"}
              fontWeight={"500"}
              lineHeight={"22px"}
            >
              Your credit card isn't active yet. To proceed, please click the
              button below to{" "}
              <Text color={"#6D3078"} fontWeight={"700"}>
                activate your card.
              </Text>
            </Text>
            <View backgroundColor={"#FEF2F0"} mt={"16px"} p={"12px"}>
              <Text
                color={"#303030"}
                fontFamily={"Quicksand"}
                fontSize={"12px"}
                fontWeight={"700"}
                lineHeight={"20px"}
              >
                <Text color={"#6D3078"}>Note: </Text>
                Once activated, it may take up to 48 hours for you to access the
                manage credit card section.
              </Text>
            </View>
          </View>
          <View
            justifyContent={"center"}
            w={"100%"}
            gap={"16px"}
            flexDir={"row"}
            paddingBottom={"32px"}
            paddingTop={"10px"}
            px={"8px"}
          >
            <Button
              onPress={handleCloseClick}
              w={"45%"}
              background={"white"}
              borderColor={"#F37435"}
              color={"#F37435"}
              borderWidth={"1px"}
              h={"42px"}
              borderRadius={"4px"}
            >
              <Text
                color={"#F37435"}
                fontWeight={"700"}
                fontSize={"14px"}
                fontFamily={"Quicksand"}
              >
                Close
              </Text>
            </Button>
            <Button
              onPress={handleActivateClick}
              w={"45%"}
              background={"#F37435"}
              h={"42px"}
              borderRadius={"4px"}
            >
              <Text
                color={"white"}
                fontWeight={"700"}
                fontSize={"14px"}
                fontFamily={"Quicksand"}
              >
                Activate
              </Text>
            </Button>
          </View>
        </View>
      </CustomModal>
    </View>
  );
};

export default Otp;
