import { Image, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native-web";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ManageBenefitService from "../../../services/ManageBenefitService";
import { consoleError } from "../../../utils/functions";
import Loader from "../../core/loader";
import Close from "../../svg/close";
import AddBenefitSection from "../Components/AddBenefitSection";
import {
  setBenefitsAddition,
  setErrorDetails,
  setMaxLoungeCount,
} from "../../../store/actions/ManageBenefits";
import Header from "../Components/Header";
import logger from "../../../utils/logger";
import queryString from "query-string";
import useNavigateToPath from "../../../hooks/useNavigateToPath";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { TrackEventTypes } from "../../../utils/enums";

const AddBenefit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();
  const location = useLocation();
  const [availableBenefits, setAvailableBenefits] = useState([]);
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const programConfigsData = useSelector((state) => state.programConfigs?.data);
  const navigateToPath = useNavigateToPath();
  const [loading, setLoading] = useState(true);
  const trackEventsAdobeAnalytics = useTrackEvents();
  useEffect(() => {
    (async () => {
      let accountId = null;
      try {
        const response = await ManageBenefitService.getAllBenefits({
          subscriptionStatus: true,
          accountId: manageBenefits.cardId,
        });
        let response2 = null;
        if (programConfigsData?.featurePrioritisationConfig?.isEnabled) {
          response2 = await ManageBenefitService.getBenefitStats({
            type: "FEATURE_PRIORITISATION",
            accountId: manageBenefits.cardId,
          });
        }
        const result = response.data;
        const result2 = response2?.data;

        const queryParams = queryString.parse(location.search);
        accountId = queryParams.accountId || localStorage.getItem("cardId");
        if (response.status === 200) {
          let modifiedAvailableBenefits = [];
          let modifiedBenefitStatObject = {};
          if (response2 && response2?.status === 200) {
            for (let j = 0; j < result2.benefitNudges.length; j++) {
              modifiedBenefitStatObject[result2.benefitNudges[j].benefitId] = {
                text: result2.benefitNudges[j].text,
                priority: result2.benefitNudges[j].priority,
              };
            }
          }

          for (let i = 0; i < result.benefits.length; i++) {
            let tempItem = result.benefits[i];
            tempItem.nudgeDetails =
              modifiedBenefitStatObject[result.benefits[i].id] || {};
            modifiedAvailableBenefits.push(tempItem);
          }
          setAvailableBenefits(modifiedAvailableBenefits);
          const temp = result.benefits.filter((item) => {
            return item.subscribed;
          });
          let maxLoungeCount = 0;
          for (let i = 0; i < result.benefits.length; i++) {
            if (result.benefits[i].type === "AIRPORT_LOUNGE") {
              maxLoungeCount = result.benefits[i].maxConcurrentVouchers;
              break;
            }
          }
          dispatch(
            setMaxLoungeCount({
              leadId: result.leadId,
              maxLoungeCount:
                maxLoungeCount -
                Number(
                  result.countOfActiveLoungeVouchers
                    ? result.countOfActiveLoungeVouchers
                    : 0
                ),
            })
          );
          const tempGrouping = [];
          const tempId = [];
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].benefitGroup) {
              if (!tempGrouping.includes(temp[i].benefitGroup.id)) {
                tempGrouping.push(temp[i].benefitGroup.id);
                tempId.push(temp[i].id);
              }
            }
          }
          dispatch(
            setBenefitsAddition({
              benefits: [...manageBenefits.benefitsList],
              benefitsListId: [...manageBenefits.benefitsListId],
              benefitsGroupId: [
                ...manageBenefits.benefitsGroupId,
                ...tempGrouping,
              ],
            })
          );
        } else {
          logger.error({
            message: `An error occurred while fetching all benefits for accountId ${accountId}`,
            responseData: result,
            accountId: accountId,
            correlationId: response.headers.x_correlation_id,
          });
          if (response.status === 403) {
            if (response.data === "") {
              navigate("/Features/Forbidden");
            } else {
              dispatch(
                setErrorDetails({
                  errorDetails: response.data,
                })
              );

              navigate("/Features/Error");
            }
          } else {
            navigate("/Features/GenericError", {
              state: {
                x_correlation_id: response.headers.x_correlation_id,
              },
            });
          }
          consoleError("Fetching available and active features failed");
        }
      } catch (error) {
        logger.error({
          message: `An exception occurred while fetching all benefits for accountId ${accountId}`,
          error: error.message,
          accountId: accountId,
          stackTrace: error.stack,
        });
        consoleError(error);
      }
      setLoading(false);
    })();
  }, []);

  const handleExitAddFeaturePage = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.EXIT_ADD_FEATURES,
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
    });
    navigateToPath("/Features/ManageFeatures");
  };

  const handleGoBack = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.LEFT_ARROW_CLICK,
      walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
    });
    navigateToPath("/Features/ManageFeatures");
  }

  return loading ? (
    <View alignItems="center" justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} bgColor="#FFFFFF">
        <View
          w="100%"
          _web={{ maxW: "412px" }}
          alignItems="center"
          justifyContent="center"
          bgColor={"#FFFFFF"}
          height={dimensions.height}
        >
          <Loader color={"#F37435"} width={120} height={120} />
        </View>
      </View>
    </View>
  ) : (
    <View alignItems="center" justifyContent="center" bgColor={"#FFFDFA"}>
      <View _web={{ maxW: "412px" }} bgColor="#FFFFFF" width={"100%"}>
        {
          <>
            <Header />
          </>
        }
        {
          <View
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            px="16px"
            py="20px"
          >
            <View flexDirection="row" alignItems="center">
              <View
                mr="8px"
                alignItems="center"
                cursor="pointer"
                onClick={handleGoBack}
              >
                <Image
                  source={{
                    uri:
                      process.env.REACT_APP_AWS_S3_BUCKET_URL + "backArrow.svg",
                  }}
                  height="24px"
                  width="24px"
                  alt=""
                />
              </View>
              <Text
                color="#6D3078"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="20px"
                lineHeight="24px"
              >
                Add Features
              </Text>
            </View>
            <View cursor="pointer" onClick={() => handleExitAddFeaturePage()}>
              <Close size="24" color="#6D3078" />
            </View>
          </View>
        }
        <View mt="16px">
          <AddBenefitSection
            availableBenefits={availableBenefits}
            isCategorized={true}
          />
        </View>
      </View>
    </View>
  );
};

export default AddBenefit;
