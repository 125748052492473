import { Image, Text, View } from "native-base";
import React, { useState, useEffect } from "react";
import { useWindowDimensions } from "react-native";
import { AddingCard } from "./BenefitsCards";
import { useLocation, useNavigate } from "react-router-dom";

import CustomLoader from "../../core/loader";
import { useSelector } from "react-redux";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { getPageNameByRoute, truncateString } from "../../../utils/functions";
import { useTrackEvents } from "../../../hooks/useTrackEvents";
import { BenefitIssuanceType, BenefitTypes, TrackEventTypes } from "../../../utils/enums";
// import _satellite from

const AddBenefitSection = (props) => {
  const dimensions = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const manageBenefits = useSelector((state) => state.manageBenefits);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [activeType, setActiveType] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [benefitTypes, setBenefitTypes] = useState([]);

  const [data, setData] = useState();
  const [subCategory, setSubCategory] = useState();

  const trackEventsAdobeAnalytics = useTrackEvents();

  useEffect(() => {
    var types = [];
    var category = [];

    if (props?.availableBenefits?.length !== 0) {
      for (var i = 0; i < props.availableBenefits.length; i++) {
        if (!types.includes(props.availableBenefits[i].benefitCategory.id)) {
          types.push(props.availableBenefits[i].benefitCategory.id);
          category.push({
            type: props.availableBenefits[i].benefitCategory.id,
            label: props.availableBenefits[i].benefitCategory.displayTitle,
            category: props.availableBenefits[i].type,
            priority: props.availableBenefits[i].benefitCategory.priority,
          });
        }
      }
      types = [];
      category.sort((a, b) => a.priority - b.priority);
      for (i in category) {
        types.push(category[i].type);
      }
      setBenefitTypes(category);
      if (location?.state?.category) {
        setSubCategory(location.state.category.category);
        setActiveType(location.state.category.type);
        setActiveIndex(types.indexOf(location.state.category.type));
      } else {
        setSubCategory(category[0].category);
        setActiveType(types[0]);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    var benefitSection = props.isFullList
      ? props?.availableBenefits
      : props?.availableBenefits?.filter((item) => {
          return item.benefitCategory.id === activeType;
        });

    benefitSection = benefitSection.reduce((result, item) => {
      const { benefitStartDate, benefitEndDate, benefitIssuanceType, type } =
        item;

      if (benefitStartDate && benefitEndDate) {
        const startDate = new Date(benefitStartDate).setHours(0, 0, 0, 0);
        const endDate = new Date(benefitEndDate).setHours(23, 59, 59, 999);
        const today = new Date().setHours(0, 0, 0, 0);

        if (startDate <= today && today <= endDate) {
          const updatedItem =
          benefitIssuanceType === BenefitIssuanceType.END_OF_PERIOD &&
            type === BenefitTypes.POSHVINE_VOUCHER
              ? { ...item, isLeavingSoon: true }
              : item;

          result.push(updatedItem);
        }
      }

      return result;
    }, []);

    setData(benefitSection);
  }, [activeType]);
  const handleTrackEvent = (category) => {
    let categoriesEventName = {
      CASHBACK: "CB Selection",
      FUEL_SURCHARGE_WAIVER: "FSW Benefit Selection",
      POSHVINE_VOUCHER: "MB Benefit Selection",
      REWARD_ACCELERATOR: "AR Benefit Selection",
      AIRPORT_LOUNGE: "Lounge Benefit Selection",
    };
    if (categoriesEventName[category]) {
      trackEventsAdobeAnalytics({
        linkName: categoriesEventName[category],
        linkPageName: getPageNameByRoute(location.pathname),
      });
    }
  };

  const handleProceedButton = () => {
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.PROCEED,
    });
    navigate(
      props.isOnboarding
        ? "/Features/Onboarding/Checkout"
        : "/Features/ManageFeatures/Checkout"
    );
  };

  return loading ? (
    <View
      w="100%"
      alignItems="center"
      justifyContent="center"
      height={dimensions.height}
    >
      <CustomLoader color="#303030" width={120} height={120} />
    </View>
  ) : props.availableBenefits.length > 0 ? (
    <View _web={{ maxW: "412px" }} bgColor="#FFFFFF">
      <View mx="16px" mb="16px" display={props.isOnboarding ? "none" : "flex"}>
        <View>
          <Text
            color="#303030"
            fontWeight="700"
            fontFamily="Quicksand"
            fontSize="24px"
            lineHeight="32px"
          >
            You are missing out on your custom features!
          </Text>
        </View>
        <View mt="4px" w="95%">
          <Text
            color="#636D7D"
            fontWeight="500"
            fontFamily="Quicksand"
            fontSize="12px"
            lineHeight="20px"
          >
            Add features to your card to start earning cashback and rewards
          </Text>
        </View>
      </View>
      <View mx="16px" display={props.isFullList ? "none" : "flex"}>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={30}
          totalSlides={benefitTypes.length}
          visibleSlides={2.1}
          currentSlide={activeIndex}
        >
          <Slider>
            {benefitTypes.map((item, index) => (
              <Slide index={index}>
                <View
                  bg={activeType === item.type ? "#FEFAFF" : "#FFF1EB"}
                  borderRadius="4px"
                  mr="8px"
                  px="12px"
                  py="6px"
                  borderWidth="2px"
                  borderColor={activeType === item.type ? "#6D3078" : "#FFDDCC"}
                  cursor="pointer"
                  onClick={() => {
                    setActiveType(item.type);
                    setActiveIndex(index);
                    setSubCategory(item.category);
                    handleTrackEvent(item.category);
                  }}
                >
                  <View
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="row"
                  >
                    <Text
                      color={activeType === item.type ? "#6D3078" : "#FFAA80"}
                      fontWeight="600"
                      fontFamily="Quicksand"
                      fontSize="12px"
                      lineHeight="20px"
                    >
                      {dimensions.width < 350
                        ? truncateString(item.label, 14)
                        : truncateString(item.label, 18)}
                    </Text>
                  </View>
                </View>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </View>
      <View mb="80px">
        {loading ? (
          <></>
        ) : data.length === 0 && !props.isCategorized ? (
          <>
            <View mx="16px" mt="16px">
              <View
                my="16px"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
              >
                <View h="1px" bgColor="#D7DBE0" w="40%"></View>
                <View mx="8px">
                  <Image
                    source={{
                      uri:
                        process.env.REACT_APP_AWS_S3_BUCKET_URL +
                        "completionTick.svg",
                    }}
                    height="56px"
                    width="56px"
                    alt=""
                  />
                </View>
                <View h="1px" bgColor="#D7DBE0" w="40%"></View>
              </View>
              <View my="8px" alignItems="center" justifyContent="center">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  You’ve activated all the Features
                </Text>
                <Text
                  color="#303030"
                  fontWeight="500"
                  fontFamily="Quicksand"
                  fontSize="12px"
                  lineHeight="20px"
                >
                  We will let you know if anything new comes up.
                </Text>
              </View>
            </View>
          </>
        ) : (
          <View>
            {subCategory === "AIRPORT_LOUNGE" && (
              <View mx="20px">
                <Text
                  color="#303030"
                  fontWeight="700"
                  fontFamily="Quicksand"
                  fontSize="14px"
                  lineHeight="22px"
                >
                  {manageBenefits.maxLoungeCount === 0
                    ? `Sorry, Max Voucher Count exhausted`
                    : `Lounge Access (Select upto ${manageBenefits.maxLoungeCount} visits)`}
                </Text>
              </View>
            )}
            <View
              mx="16px"
              mb="24px"
              mt={subCategory === "AIRPORT_LOUNGE" ? "16px" : "2px"}
            >
              {data.map((item, index) => (
                <View key={index} mb="16px">
                  <AddingCard
                    isOnboarding={props.isOnboarding}
                    activeType={benefitTypes[activeIndex]}
                    isFullList={props.isFullList}
                    data={item}
                    id={item.id}
                    selectedBenefits={manageBenefits.benefitsList}
                    setSelectedBenefits={setSelectedBenefits}
                  />
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
      <View
        alignItems="center"
        justifyContent="center"
        position="fixed"
        _web={{ maxW: "412px" }}
        width="100%"
        bottom="0px"
        borderTopWidth="1px"
        borderTopColor="#EBEDF0"
      >
        {manageBenefits.benefitsList.length > 0 && (
          <View
            bgColor="#FFFFFF"
            width="100%"
            pt="16px"
            px="16px"
            pb="36px"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
          >
            <View alignItems="center">
              <Text
                color="#303030"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="16px"
                lineHeight="24px"
              >{`${manageBenefits.benefitsList.length} Feature(s) selected`}</Text>
            </View>
            <View
              px="50px"
              py="10px"
              bgColor="#F37435"
              cursor="pointer"
              onClick={handleProceedButton}
            >
              <Text
                color="#FFFFFF"
                fontWeight="700"
                fontFamily="Quicksand"
                fontSize="14px"
                lineHeight="22px"
              >
                Proceed
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  ) : (
    <View _web={{ maxW: "412px" }} justifyContent="center" alignItems=" center">
      <View mt="16px">
        <View
          my="16px"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <View h="1px" bgColor="#D7DBE0" w="40%"></View>
          <View mx="8px">
            <Image
              source={{
                uri:
                  process.env.REACT_APP_AWS_S3_BUCKET_URL +
                  "completionTick.svg",
              }}
              height="56px"
              width="56px"
              alt=""
            />
          </View>
          <View h="1px" bgColor="#D7DBE0" w="40%"></View>
        </View>
        <View my="8px" alignItems="center" justifyContent="center">
          <Text
            color="#303030"
            fontWeight="700"
            fontFamily="Quicksand"
            fontSize="14px"
            lineHeight="22px"
          >
            You've activated all the Features
          </Text>
          <Text
            color="#303030"
            fontWeight="500"
            fontFamily="Quicksand"
            fontSize="12px"
            lineHeight="20px"
            marginBottom={"16px"}
          >
            We will let you know if anything new comes up.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default AddBenefitSection;
