import { Image, Text, View } from "native-base";
import React, { useEffect, useState } from "react";
import ActiveBenefitSection from "./Components/ActiveBenefitSection";
import AddBenefitSection from "./Components/AddBenefitSection";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "react-native-web";
import ManageBenefitService from "../../services/ManageBenefitService";
import {
  benefitNameWithId,
  handleApiError,
  isApiSuccessful,
} from "../../utils/functions";
import Loader from "../core/loader";
import GenericError from "./Error/4xxError";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import {
  setBenefitsAddition,
  setBenefitsCardId,
  setCallBackUrl,
  setMaxLoungeCount,
} from "../../store/actions/ManageBenefits";
import { setWheelProgram } from "../../store/actions/WheelProgram";
import Header from "./Components/Header";
import FortuneWheelService from "../../services/FortuneWheelService";
import logger from "../../utils/logger";
import {
  Channels,
  TrackEventTypes,
  WheelBucketCategories,
} from "../../utils/enums";
import Paths from "../../routes/Paths";
import { useTrackEvents } from "../../hooks/useTrackEvents";
import GiftSvg from "../../assets/images/Gift.svg";
import useNavigateToPath from "../../hooks/useNavigateToPath";

const ManageBenefits = () => {
  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateToPath = useNavigateToPath();

  const manageBenefits = useSelector((state) => state.manageBenefits);
  const wheelProgram = useSelector((state) => state.wheelProgram);
  const programConfigsData = useSelector((state) => state.programConfigs.data);
  const programConfigsStatus = useSelector(
    (state) => state.programConfigs.status
  );

  const [activeBenefits, setActiveBenefits] = useState([]);
  const [availableBenefits, setAvailableBenefits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [triggerTrackEvent, setTriggerTrackEvent] = useState(false);
  const trackEventsAdobeAnalytics = useTrackEvents();

  const redirect = () => {
    window.location.href = manageBenefits.callBackUrl;
    trackEventsAdobeAnalytics({
      linkName: TrackEventTypes.LEFT_ARROW_CLICK,
    });
  };

  useEffect(() => {
    (async () => {
      let accountId = null;

      try {
        const queryParams = queryString.parse(location.search);
        if (!queryParams.accountId || !queryParams.channel) {
          setLoading(false);
          setError(true);
          navigate(Paths.GENERIC_ERROR);
          return;
        }
        if (programConfigsStatus !== "SUCCESS") {
          return;
        }
        accountId = queryParams.accountId || localStorage.getItem("cardId");
        const channel = queryParams.channel;

        dispatch(
          setBenefitsCardId({
            accountId,
            cardId: accountId,
            channel,
          })
        );

        const isOnboarding =
          channel === Channels.CCA || channel === Channels.CCO;
        // if wheel program is not already fetched and channel is not onboarding then fetch wheel data
        const shouldGetWheelProgram = !wheelProgram.id && !isOnboarding;

        let availableBenefitsResponse;
        let subscribedBenefitsResponse;
        let benefitStatsResponse;
        let wheelProgramResponse = null;
        // call apis in parallel
        if (Promise?.allSettled) {
          const subscribedBenefitsPromise =
            ManageBenefitService.getSubscribedBenefits(accountId);
          const availableBenefitsPromise =
            ManageBenefitService.getAvailableBenefits(accountId);
          let benefitStatsPromise = null;
          if (programConfigsData?.featurePrioritisationConfig?.isEnabled) {
            benefitStatsPromise = ManageBenefitService.getBenefitStats({
              type: "FEATURE_PRIORITISATION",
              accountId: accountId,
            });
          }
          const promises = [
            subscribedBenefitsPromise,
            availableBenefitsPromise,
            benefitStatsPromise,
          ];

          if (shouldGetWheelProgram) {
            const wheelProgramPromise =
              FortuneWheelService.getProgram(accountId);
            promises.push(wheelProgramPromise);
          }

          // responses
          const responses = await Promise.allSettled(promises);
          subscribedBenefitsResponse = responses[0].value;
          availableBenefitsResponse = responses[1].value;
          benefitStatsResponse = responses[2].value;

          if (shouldGetWheelProgram) {
            wheelProgramResponse = responses[3].value;
          }
        } else {
          // promises
          subscribedBenefitsResponse =
            await ManageBenefitService.getSubscribedBenefits(accountId);
          availableBenefitsResponse =
            await ManageBenefitService.getAvailableBenefits(accountId);
          if (programConfigsData?.featurePrioritisationConfig?.isEnabled) {
            benefitStatsResponse = await ManageBenefitService.getBenefitStats({
              type: "FEATURE_PRIORITISATION",
              accountId: accountId,
            });
          }
          if (shouldGetWheelProgram) {
            wheelProgramResponse = await FortuneWheelService.getProgram(
              accountId
            );
          }
        }

        // result
        const subscribedBenefitsResult = subscribedBenefitsResponse.data;
        const availableBenefitsResult = availableBenefitsResponse.data;
        const benefitStatsResult = benefitStatsResponse?.data;
        let wheelProgramResult = null;

        if (shouldGetWheelProgram) {
          wheelProgramResult = wheelProgramResponse.data;
        }

        if (
          isApiSuccessful(subscribedBenefitsResponse) &&
          isApiSuccessful(availableBenefitsResponse)
        ) {
          const temp = subscribedBenefitsResult.benefits;

          const tempGrouping = [];
          const tempId = [];
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].benefit.benefitGroup) {
              if (!tempGrouping.includes(temp[i].benefit.benefitGroup.id)) {
                tempGrouping.push(temp[i].benefit.benefitGroup.id);
                tempId.push(temp[i].benefit.id);
              }
            }
          }
          let maxLoungeCount = 0;
          for (let i = 0; i < availableBenefitsResult.benefits.length; i++) {
            if (availableBenefitsResult.benefits[i].type === "AIRPORT_LOUNGE") {
              maxLoungeCount =
                availableBenefitsResult.benefits[i].maxConcurrentVouchers;
              break;
            }
          }
          dispatch(
            setMaxLoungeCount({
              leadId: null,
              maxLoungeCount:
                maxLoungeCount -
                Number(
                  availableBenefitsResult.countOfActiveLoungeVouchers
                    ? availableBenefitsResult.countOfActiveLoungeVouchers
                    : 0
                ),
            })
          );
          dispatch(
            setBenefitsAddition({
              benefits: [...manageBenefits.benefitsList],
              benefitsListId: [...manageBenefits.benefitsListId],
              benefitsGroupId: [
                ...manageBenefits.benefitsGroupId,
                ...tempGrouping,
              ],
            })
          );
          setActiveBenefits(subscribedBenefitsResult.benefits);
          let modifiedAvailableBenefits = [];
          let modifiedBenefitStatObject = {};
          if (benefitStatsResult && isApiSuccessful(benefitStatsResponse)) {
            for (let j = 0; j < benefitStatsResult.benefitNudges.length; j++) {
              modifiedBenefitStatObject[
                benefitStatsResult.benefitNudges[j].benefitId
              ] = {
                text: benefitStatsResult.benefitNudges[j].text,
                priority: benefitStatsResult.benefitNudges[j].priority,
              };
            }
          }

          let tempItemWithStats = [];
          let tempItemWithoutStats = [];
          for (let i = 0; i < availableBenefitsResult.benefits.length; i++) {
            let tempItem = availableBenefitsResult.benefits[i];
            tempItem.nudgeDetails =
              modifiedBenefitStatObject[
                availableBenefitsResult.benefits[i].id
              ] || {};
            if (
              modifiedBenefitStatObject[availableBenefitsResult.benefits[i].id]
            ) {
              tempItemWithStats.push(tempItem);
            } else {
              tempItemWithoutStats.push(tempItem);
            }
          }
          tempItemWithStats.sort((a, b) => {
            if (a?.nudgeDetails?.priority < b?.nudgeDetails?.priority) {
              return -1;
            }
            if (a?.nudgeDetails?.priority > b?.nudgeDetails?.priority) {
              return 1;
            }
            return 0;
          });

          modifiedAvailableBenefits = [
            ...tempItemWithStats,
            ...tempItemWithoutStats,
          ];
          setAvailableBenefits(modifiedAvailableBenefits);
          if (subscribedBenefitsResult.callbackUrl) {
            dispatch(
              setCallBackUrl({
                callBackUrl: subscribedBenefitsResult.callbackUrl,
              })
            );
          } else {
            dispatch(
              setCallBackUrl({
                callBackUrl:
                  "https://www.aubank.in/personal-banking/credit-cards",
              })
            );
          }
        } else if (!isApiSuccessful(subscribedBenefitsResponse)) {
          // log error
          logger.error({
            message: `An error occurred while fetching subscribed benefits for accountId ${accountId}`,
            responseData: subscribedBenefitsResult,
            accountId: accountId,
            correlationId: subscribedBenefitsResponse.headers.x_correlation_id,
          });

          // handle error
          handleApiError(subscribedBenefitsResponse, navigate);
        } else if (!isApiSuccessful(availableBenefitsResponse)) {
          logger.error({
            message: `An error occurred while fetching available benefits for accountId ${accountId}`,
            responseData: availableBenefitsResult,
            accountId: accountId,
            correlationId: availableBenefitsResponse.headers.x_correlation_id,
          });

          handleApiError(availableBenefitsResponse, navigate);
        }

        // if wheel program api failed then the banner would not be shown, this api is not a blocker
        if (shouldGetWheelProgram && isApiSuccessful(wheelProgramResponse)) {
          const {
            buckets: resultBuckets,
            availableSpins: resultAvailableSpins,
            availableRewardSpins: resultAvailableRewardSpins,
          } = wheelProgramResult;

          const buckets = Array.isArray(resultBuckets)
            ? [...resultBuckets]
            : [];
          const availableSpins =
            typeof resultAvailableSpins === "number" && resultAvailableSpins > 0
              ? resultAvailableSpins
              : 0;
          const availableRewardSpins =
            typeof resultAvailableRewardSpins === "number" &&
            resultAvailableRewardSpins > 0
              ? resultAvailableRewardSpins
              : 0;

          // get max reward
          let tempBuckets = [...buckets];
          tempBuckets = tempBuckets.filter(
            (tempBucket) => tempBucket.category === WheelBucketCategories.HIT
          );
          tempBuckets.sort((a, b) => b.rewardValue - a.rewardValue);

          const maxReward =
            tempBuckets.length > 0 &&
            typeof tempBuckets[0].rewardValue === "number" &&
            tempBuckets[0].rewardValue > 0
              ? tempBuckets[0].rewardValue
              : 0;

          dispatch(
            setWheelProgram({
              ...wheelProgramResult,
              buckets,
              availableSpins,
              availableRewardSpins,
              maxReward,
            })
          );
        }
      } catch (error) {
        // log exception
        logger.error({
          message: `An exception occurred while fetching subscribed and active benefits for accountId ${accountId}`,
          error: error.message,
          accountId: accountId,
          stackTrace: error.stack,
        });

        // handle exception
        setError(true);
      }

      // stop loading
      setLoading(false);
      setTriggerTrackEvent(true);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programConfigsStatus, programConfigsData]);

  useEffect(() => {
    const triggerAdobeEvent = JSON.parse(
      sessionStorage.getItem("triggerAdobeEvent")
    );
    if (
      triggerAdobeEvent &&
      "totalBalanceAmount" in manageBenefits?.walletDetails &&
      !loading
    ) {
      sessionStorage.setItem("triggerAdobeEvent", false);
      trackEventsAdobeAnalytics({
        linkName: TrackEventTypes.OTP_LOGIN,
        walletBalance: manageBenefits?.walletDetails?.totalBalanceAmount,
        otpSubmitted: "y",
        benefitsName: activeBenefits?.map((benefit) =>
          benefitNameWithId(benefit?.benefit?.title, benefit?.benefit?.id)
        ),
        leadSubmit: "y",
      });
    }
  }, [manageBenefits, loading, triggerTrackEvent]);

  return loading ? (
    <>
      <View
        w="100%"
        alignItems="center"
        justifyContent="center"
        height={dimensions.height}
      >
        <Loader color={"#F37435"} width={120} height={120} />
      </View>
    </>
  ) : error ? (
    <GenericError />
  ) : (
    <View w="100%" alignItems="center" bgColor={"#FFFDFA"}>
      <View
        h={dimensions.height}
        _web={{ maxW: "412px" }}
        w="100%"
        bgColor="#FFFFFF"
      >
        <>
          {(manageBenefits.channel === Channels.CCA ||
            manageBenefits.channel === Channels.CCO) && (
            <>
              <Header page="manageFeatures" />
              <View
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                p="16px"
                shadow="1"
              >
                <View flexDirection="row" alignItems="center">
                  <View
                    mr="8px"
                    cursor="pointer"
                    alignItems="center"
                    onClick={() => redirect()}
                  >
                    <Image
                      source={{
                        uri:
                          process.env.REACT_APP_AWS_S3_BUCKET_URL +
                          "backArrow.svg",
                      }}
                      height="24px"
                      width="24px"
                      alt=""
                    />
                  </View>
                  <Text
                    color="#303030"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="18px"
                    lineHeight="26px"
                  >
                    Manage Features
                  </Text>
                </View>
              </View>
            </>
          )}
          {manageBenefits.channel === Channels.IB && (
            <>
              <Header page="manageFeatures" />
              <View
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                p="16px"
                shadow="1"
              >
                <View flexDirection="row" alignItems="center">
                  <View
                    mr="8px"
                    cursor="pointer"
                    alignItems="center"
                    onClick={() => redirect()}
                  >
                    {/* <Image
                      source={{ uri: back }}
                      height="24px"
                      width="24px"
                      alt=""
                    /> */}
                  </View>
                  <Text
                    color="#303030"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="18px"
                    lineHeight="26px"
                  >
                    Manage Features
                  </Text>
                </View>
                <View></View>
              </View>
            </>
          )}
          {manageBenefits.channel === Channels.CCS && (
            <>
              <Header page="manageFeatures" />
              <View
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                p="16px"
                shadow="1"
              >
                <View flexDirection="row" alignItems="center">
                  <View
                    mr="8px"
                    cursor="pointer"
                    alignItems="center"
                    onClick={() => redirect()}
                  >
                    {/* <Image
                      source={{ uri: back }}
                      height="24px"
                      width="24px"
                      alt=""
                    /> */}
                  </View>
                  <Text
                    color="#303030"
                    fontWeight="700"
                    fontFamily="Quicksand"
                    fontSize="18px"
                    lineHeight="26px"
                  >
                    Manage Features
                  </Text>
                </View>
                <View></View>
              </View>
            </>
          )}
          {manageBenefits.channel === Channels.MB && (
            <Header page="manageFeatures" />
          )}
          {manageBenefits.channel !== Channels.CCA &&
            manageBenefits.channel !== Channels.CCO && (
              <View
                flexDir={"row"}
                justifyContent={"space-between"}
                py={"10px"}
                px={"12px"}
                width={"93%"}
                margin={"auto"}
                marginTop={"16px"}
                marginBottom={activeBenefits.length > 0 ? "0px" : "6px"}
                borderRadius={"8px"}
                background={"#FEF2F0"}
                onClick={() => {
                  navigateToPath("/Features/ManageFeatures/EarnedVouchers");
                }}
              >
                <View flexDir={"row"} alignItems={"center"} gap={"4px"}>
                  <Image
                    source={{
                      uri: GiftSvg,
                    }}
                    height="20px"
                    width="20px"
                    alt=""
                  />
                  <View
                  color={"#303030"}
                  fontWeight={"700"}
                  fontFamily={"Quicksand"}
                  fontSize={"16px"}
                  lineHeight={"20px"}
                  >Earned Vouchers</View>
                </View>
                <Text
                  color="#303030"
                  fontWeight="500"
                  marginTop={"2px"}
                  fontFamily="Quicksand"
                  fontSize="26px"
                  textAlign={"center"}
                  lineHeight="20px"
                >
                  {`>`}
                </Text>
              </View>
            )}

          {activeBenefits.length > 0 && (
            <View
              borderBottomColor="#EEF0F3"
              borderBottomWidth="8px"
              borderRadius="4px"
              mb="16px"
            >
              <ActiveBenefitSection activeBenefits={activeBenefits} />
            </View>
          )}
        </>
        <View>
          <AddBenefitSection
            isFullList={activeBenefits.length > 0}
            availableBenefits={availableBenefits}
            isCategorized={false}
          />
        </View>
      </View>
    </View>
  );
};

export default ManageBenefits;
