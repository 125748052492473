import BenefitService from "./BenefitService";

const ManageBenefitService = {
  getSubscribedBenefits: (accountId) =>
    BenefitService.get(`/account/getSubscribedBenefits/${accountId}`),
  getBenefitDetails: (benefitId) =>
    BenefitService.get(`/benefitSubscription/fetchDetails/${benefitId}`),
  getAvailableBenefits: (accountId) =>
    BenefitService.get(`/account/getAvailableBenefits/${accountId}`),
  getAllBenefits: (data) =>
    BenefitService.post(`/benefit/getAllBenefits`, data),
  getSummary: (accountId) =>
    BenefitService.post(`/account/getSummary/${accountId}`),
  activateOnboardingBenefits: (data, accountId) =>
    BenefitService.post(`/account/benefits/${accountId}`, data),
  activateBenefits: (data, accountId) =>
    BenefitService.post(`/account/activateBenefits/${accountId}`, data),
  fetchVoucher: (benefitSubscriptionId) =>
    BenefitService.get(
      `/benefitSubscription/loungeVoucher/${benefitSubscriptionId}`
    ),
  fetchMembershipVoucher: (benefitSubscriptionId) =>
    BenefitService.get(
      `/benefitSubscription/membershipVoucher/${benefitSubscriptionId}`
    ),
  updateRenewalStatus: (benefitId, data) =>
    BenefitService.post(
      `/benefitSubscription/updateRenewalStatus/${benefitId}`,
      data
    ),
  generateOtp: (data) => BenefitService.post(`/otp/generate`, data),
  generateCcaOtp: (data) => BenefitService.post(`/otp/generateCcaOtp`, data),
  resendLoginOtp: (data) => BenefitService.post(`/otp/resend`, data),
  verifyLoginOtp: (data) => BenefitService.post(`/otp/verify`, data),
  manageCard: (data) => BenefitService.post(`/customFeatures/manage`, data),
  getBenefitStats: (data) => BenefitService.post(`/benefit/statistics`, data),
  getProgramConfigs:(programId,accountId)=>BenefitService.get(`/program/${programId}/configs?accountId=${accountId}`),
  getEarnedBenefits: (accountId) => BenefitService.get(`/account/earned?id=${accountId}`),
};

export default ManageBenefitService;
